import { TicketingLink } from "@components/TicketingLink";

import { useTranslations } from "next-intl";
import OpenTicketLogo from "./OpenTicketLogo";

import { LanguageSelector } from "./LanguageSelector";

const TicketingFooter = () => {
  const t = useTranslations("Ticketing.components.Footer");
  return (
    <footer className="page-footer relative bg-white pb-5 dark:bg-jacarta-900">
      <div className="container">
        <div className="py-4 sm:py-8">
          <div className="mx-auto w-fit md:mx-0 md:w-auto">
            <TicketingLink href="/" className="block w-max">
              <OpenTicketLogo
                width={230}
                className="mb-2 fill-black dark:fill-stone-100"
              />
            </TicketingLink>
            <p className="mb-6 dark:text-stone-300 sm:mb-12">{t("aboutUs")}</p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between space-y-2 py-2 sm:flex-row sm:space-y-0 sm:py-8">
          <span className="text-sm dark:text-stone-400">
            &copy; OpenTicket LTDA {new Date().getFullYear()}
          </span>
          <ul className="flex flex-col flex-wrap items-center gap-4 pt-3 text-sm dark:text-stone-400 md:flex-row md:pt-0">
            <LanguageSelector className="w-max" />

            <li>
              <TicketingLink
                href="/terms-of-service"
                className="hover:text-accent dark:hover:text-white"
              >
                {t("termsAndConditions")}
              </TicketingLink>
            </li>
            {/* <li>
              <a href="#" className="hover:text-accent dark:hover:text-white">
                Privacy policy
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};
export { TicketingFooter };
