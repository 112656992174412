import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { usePathname, useSearchParams } from "next/navigation";
import { useDebounce } from "react-use";

export function useParams(initialQuery = "", initialFilter = "", shouldManipulateRoute: boolean) {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [searchValue, setSearchValue] = useState(initialQuery);
  const [debouncedValue, setDebouncedValue] = useState(initialQuery);
  const filter = searchParams.get("filter") ?? initialFilter;

  useDebounce(
    () => {
      setDebouncedValue(searchValue.trim());
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    if (shouldManipulateRoute) {
      if (!debouncedValue && !filter) {
        void router.push(pathname)
      } else {
        const params = new URLSearchParams()
        params.set("q", debouncedValue);

        if (filter)
          params.set("filter", filter);

        void router.push(`${pathname}?${params.toString()}`);
      }
    }
  }, [debouncedValue]);

  function changeFilter(newFilter: string) {
    const params = new URLSearchParams()
    const query = searchParams.get("q");
    if (query)
      params.set("q", query);

    params.set("filter", newFilter);
    void router.push(`${pathname}?${params.toString()}`);
  }

  return {
    searchValue,
    setSearchValue,
    debouncedValue,
    changeFilter,
    filter
  }
}
