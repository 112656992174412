import cs from "classnames";
import Image from "next/image";

import { TicketingLink } from "@components/TicketingLink";

import { type RouterOutputs } from "@utils/trpc";
import { format } from "date-fns";
import { cn } from "@lib/utils";

type TicketFromGetAll =
  RouterOutputs["events"]["getUserEventsWithTicketCount"]["events"][0] & {
    count?: number;
  };

export const TicketsCard: React.FC<{
  event: TicketFromGetAll;
  showEventCount?: boolean;
  showLocation?: boolean;
  variant?: "small" | "full";
  redirectTo?: string;
  className?: string;
  imageContainerClassName?: string;
  showPublished?: boolean;
}> = ({
  event,
  redirectTo,
  showEventCount = true,
  showLocation = false,
  variant = "full",
  className,
  imageContainerClassName,
  showPublished = false,
}) => {
  const [day, month] = format(event.startsAt, "dd MMM").split(" ");
  return (
    <TicketingLink
      href={redirectTo ? redirectTo : `/event/${event.collection.slug}/tickets`}
      className={cn(
        {
          "md:max-w-[364px]": variant === "small",
        },
        className
      )}
    >
      <div
        className={cn(
          "relative z-10 flex h-72 w-auto cursor-pointer flex-col justify-between overflow-hidden rounded-lg shadow-md",
          imageContainerClassName
        )}
        key={event.id}
        role="button"
      >
        <Image
          src={event.collection.imageUrl}
          alt="Event image"
          fill
          priority
          className="absolute inset-0 -z-10 object-cover blur-xs"
        />
        <div className="m-2 inline-flex h-14 w-14 flex-col items-center justify-center self-end rounded-lg bg-stone-50 p-2 font-display dark:bg-sky-950">
          <h3 className="text-lg font-semibold leading-none">{day}</h3>
          <span className="font-light leading-none">{month}</span>
        </div>

        <div className="m-2 rounded-md bg-stone-50 p-2 dark:bg-sky-950 dark:text-white">
          <h3 className="font-display text-lg font-semibold leading-none">
            {event.collection.name}
          </h3>

          {showEventCount && <p>{event.count} tickets</p>}
          {showLocation && (
            <p className="mt-2 leading-tight text-gray-500">
              {event.venue.name}
            </p>
          )}
          {showPublished && (
            <p className="mt-2 leading-tight text-gray-500">
              {!!event.publishedAt ? "Published" : "Draft"}
            </p>
          )}
        </div>
      </div>
    </TicketingLink>
  );
};
