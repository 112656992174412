import { Sliders } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/Sheet";
import { Button } from "./Button";
import { useTranslations } from "next-intl";

const Filters = ["name", "date", "category"] as const;

type SearchFiltersProps = {
  filter: string;
  setFilter: (filter: string) => void;
};

export function SearchFilters({ filter = "", setFilter }: SearchFiltersProps) {
  const t = useTranslations("Ticketing.components.SearchFilters");

  return (
    <Sheet>
      <SheetTrigger>
        <Sliders />
      </SheetTrigger>
      <SheetContent className="w-[400px] sm:w-[540px]">
        <SheetHeader>
          <SheetTitle>{t("title")}</SheetTitle>
          <SheetDescription>{t("description")}</SheetDescription>
        </SheetHeader>
        <div className="mt-2 flex flex-col gap-2">
          {Filters.map((filterItem, index) => {
            return (
              <Button
                key={index}
                variant={filter === filterItem ? "inverse" : null}
                onClick={() => setFilter(filterItem)}
              >
                {t(filterItem)}
              </Button>
            );
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
}
