import ReactSkeleton, { type SkeletonProps } from "react-loading-skeleton";

import { useTheme } from "@hooks/theme";

export const Skeleton = (props: SkeletonProps) => {
  const { skeletonTheme } = useTheme();
  return (
    <ReactSkeleton
      {...skeletonTheme}
      {...props}
      height={props.height ?? "100%"}
      width={props.width ?? "100%"}
      className={`${props.className ?? ""} pt-1`}
    />
  );
};
