import { Fragment, useState } from "react";
import { useTranslations } from "next-intl";
import { Loader, Search } from "lucide-react";
import InfiniteScroll from "react-infinite-scroller";

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "./command";
import { SearchFilters } from "./SearchFilters";
import { TicketingLink, getTicketingHref } from "./TicketingLink";
import { trpc } from "@utils/trpc";
import { useParams } from "@hooks/useParams";
import { usePathname } from "next/navigation";
import { TicketsCard } from "./TicketsCard";
import { cn } from "@lib/utils";
import { buttonVariants } from "./ui/Button";
import Router from "next/router";

type SearchProps = {
  initialQuery?: string;
  initialFilter?: string;
  shouldManipulateRoute: boolean;
  showCard?: boolean;
  showFilters?: boolean;
};

export function SearchComponent({
  initialQuery = "",
  initialFilter = "",
  shouldManipulateRoute,
  showCard = false,
  showFilters = false,
}: SearchProps) {
  const pathname = usePathname();
  const t = useTranslations("Ticketing.components.SearchComponent");
  const { searchValue, debouncedValue, setSearchValue, changeFilter, filter } =
    useParams(initialQuery, initialFilter, shouldManipulateRoute);

  const { data, fetchNextPage, hasNextPage, isLoading } =
    trpc.events.infiniteEvents.useInfiniteQuery(
      {
        limit: 10,
        query: debouncedValue,
        filter,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        initialCursor: 1,
      }
    );

  return (
    <Command
      className={cn(
        `bg-input	max-sm:h-screen max-sm:w-screen ${
          pathname.includes("search") ? "h-[700px]" : ""
        }`
      )}
      shouldFilter={false}
    >
      <div
        className={`flex justify-between gap-2 border-b${
          showFilters ? " pr-2" : ""
        }`}
      >
        <CommandInput
          className="border-none focus:outline-none focus:ring-0"
          placeholder={t("inputPlaceholder")}
          value={searchValue}
          onValueChange={(e) => setSearchValue(e)}
        >
          {pathname.includes("search") ? (
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
          ) : (
            <TicketingLink href={`/search?q=${searchValue}`}>
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            </TicketingLink>
          )}
        </CommandInput>
        {showFilters && (
          <SearchFilters filter={filter} setFilter={changeFilter} />
        )}
      </div>
      {isLoading ? (
        <div className="flex items-center justify-evenly p-3">
          <Loader className="animate-spin" size={36} />
          <span>{t("loadingMoreData")}</span>
        </div>
      ) : (
        <CommandList className={cn("max-h-full")}>
          <CommandEmpty>{t("noDataFound")}</CommandEmpty>
          <CommandGroup>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => fetchNextPage()}
              hasMore={hasNextPage}
              loader={
                <div className="flex items-center justify-evenly">
                  <Loader className="animate-spin" size={36} />
                  <span>{t("loadingMoreData")}</span>
                </div>
              }
              className={`${
                showCard
                  ? "row-auto grid grid-cols-3 gap-4"
                  : "flex flex-col gap-1.5"
              }`}
            >
              {data &&
                data.pages.map((page, index) => {
                  return (
                    <Fragment key={index}>
                      {page.items.map((event) => {
                        return (
                          <CommandItem
                            key={event.id}
                            value={event.collection.slug}
                            className={cn(
                              showCard
                                ? "aria-selected:text-input-foreground flex items-center justify-center aria-selected:bg-popover"
                                : "aria-selected:text-input-foreground !p-0 hover:underline aria-selected:bg-popover"
                            )}
                          >
                            {showCard ? (
                              <TicketsCard
                                event={event}
                                showEventCount={false}
                                showLocation
                                redirectTo={`/event/${event.collection.slug}`}
                                className="block aspect-square h-auto w-full"
                                imageContainerClassName="h-full"
                              />
                            ) : (
                              <TicketingLink
                                href={`/event/${event.collection.slug}`}
                                onKeyDown={({ key }) => {
                                  if (key === "Enter" || key === " ") {
                                    Router.push(
                                      getTicketingHref(
                                        `/event/${event.collection.slug}`
                                      )
                                    ).catch((err) => console.error(err));
                                  }
                                }}
                                className={cn(
                                  buttonVariants({ variant: "ghost" }),
                                  "h-full w-full py-3 hover:bg-popover hover:underline"
                                )}
                              >
                                {event.collection.name}
                              </TicketingLink>
                            )}
                          </CommandItem>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </InfiniteScroll>
          </CommandGroup>
        </CommandList>
      )}
      {!pathname.includes("search") && !isLoading && (
        <>
          <CommandSeparator />
          <TicketingLink
            href={debouncedValue ? `/search?q=${debouncedValue}` : "/search"}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "justify-start rounded-none p-3 hover:bg-popover hover:underline"
            )}
          >
            {t("toSearchPage")}
          </TicketingLink>
        </>
      )}
    </Command>
  );
}

export function SearchComponentModal({
  initialQuery = "",
  shouldManipulateRoute,
}: SearchProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="mr-2 flex h-10 w-10 items-center justify-center rounded-full border border-stone-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
        onClick={() => setOpen(true)}
      >
        <Search />
      </button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <SearchComponent
          initialQuery={initialQuery}
          shouldManipulateRoute={shouldManipulateRoute}
        />
      </CommandDialog>
    </>
  );
}
