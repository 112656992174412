import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "@utils/trpc";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { Button } from "./Button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/AlertDialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input, input } from "./ui/Input";
import { useSession } from "next-auth/react";
import { cn } from "@lib/utils";
import { formSubmitRewrap } from "@utils/index";

function useFormAndTranslations() {
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  const { data: session } = useSession();
  const formSchema = z.object({
    name: z.string().min(1, { message: t("nameErrorMessage") }),
    phone: z.string().min(1, { message: t("phoneErrorMessage") }),
    description: z.string().min(1, { message: t("descriptionErrorMessage") }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: session?.user?.name || "",
    },
  });

  return { form, formSchema };
}

export function CollaboratorButton(props: { onboardingStatus?: string }) {
  const { onboardingStatus } = props;
  const t = useTranslations("Ticketing.components.CollaboratorButton");
  const [isOpen, setIsOpen] = useState(false);
  const sendInternalEmail = trpc.collaborators.sendInternalEmail.useMutation();
  const { form, formSchema } = useFormAndTranslations();
  const createOnboardingLink =
    trpc.collaborators.createOnboardingLink.useMutation();

  async function onSubmit(data: z.infer<typeof formSchema>) {
    await sendInternalEmail.mutateAsync(data);
    setIsOpen(false);
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger
        asChild
        onClick={(e) => {
          if (onboardingStatus === "pending") {
            e.preventDefault();
            e.stopPropagation();
            createOnboardingLink.mutate(undefined, {
              onSuccess({ url }) {
                window.location.href = url;
              },
            });
          }
        }}
      >
        <Button
          className="self-center"
          aria-disabled={createOnboardingLink.isLoading}
        >
          {onboardingStatus === undefined
            ? t("becomeCollaborator")
            : t("continueOnboarding")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              void toast.promise(formSubmitRewrap(form, onSubmit)(e), {
                loading: t("sendingEmail"),
                success: t("becomeCollaborator"),
                error: t("sendEmailError"),
              });
            }}
          >
            <AlertDialogHeader className="mb-2">
              <AlertDialogTitle>{t("modalTitle")}</AlertDialogTitle>
              <AlertDialogDescription>
                {t("modalDescription")}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className="mb-2 space-y-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("name")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("phone")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("description")}</FormLabel>
                    <FormControl>
                      <textarea
                        cols={6}
                        className={cn(
                          input(),
                          "focus:border-none focus:outline-current focus:ring-transparent",
                          "h-14"
                        )}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
              <Button
                className="flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-medium"
                type="submit"
              >
                {t("continue")}
              </Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
